<template>
  <div>
    <v-toolbar flat>
      <!-- <v-toolbar-title class="text-uppercase">{{ $t('title.menu_list') }}</v-toolbar-title> -->
      <v-spacer></v-spacer>
      <v-text-field
        v-model="params.search"
        append-icon="mdi-magnify"
        :label="$t('input.search')"
        single-line
        solo
        hide-details
        @input="fetchListBankUser(params)"
        class="mr-5"
      ></v-text-field>

      <v-btn
        color="error"
        elevation="5"
        @click="dialogDelete = !dialogDelete"
        :disabled="dataDelete && dataDelete.length < 1"
        v-if="checkUserPermission('DELETE')"
      >
        {{ $t("button.delete_all") }}
      </v-btn>
      <v-btn
        class="ml-5"
        elevation="5"
        color="primary"
        @click="create"
        v-if="checkUserPermission('CREATE')"
        >{{ $t("button.create") }}</v-btn
      >
      <dialog-delete
        :dialog-delete="dialogDelete"
        :darkmode="darkmode"
        v-on:closeDelete="closeDelete"
        v-on:deleteItemConfirm="deleteItemConfirm"
      />
      <DialogAction :darkmode="darkmode" />
    </v-toolbar>
  </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
  components: {
    DialogDelete: () =>
      import("../../../components/shared/notify/DialogDelete"),
    DialogAction: () => import("./Dialog.vue"),
  },
  props: ["darkmode", "params", "dataDelete", "userPermission"],
  data() {
    return {
      dialogDelete: false,
    };
  },
  methods: {
    async create() {
      await this.fetchPopUpBankUser(true);
      await this.fetchListPermission();
      await this.fetchParentBankUser();
    },
    closeDelete() {
      this.dialogDelete = !this.dialogDelete;
    },
    async deleteItemConfirm() {
      await this.deleteBankUser({
        vm: this,
        data: { deleteItems: this.dataDelete },
      });
      await this.fetchListBankUser(this.params);
      this.dialogDelete = false;
    },
    ...mapActions("$_bankUser", [
      "fetchPopUpBankUser",
      "fetchListBankUser",
      "deleteBankUser",
      "fetchParentBankUser",
    ]),
    ...mapActions("$_permission", ["fetchListPermission"]),
  },
};
</script>
